.resume_container {
  display: flex;
  flex-direction: column;
  background-color: #16161A;
  padding: 5%;
}

.resume_container .top_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resume_container .top_row h2 {
  font-size: var(--h2-font);
  line-height: 47px;
  margin: 0;
}

.resume_container .top_row .buttons {
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
}

.resume_container .top_row .buttons a {
  margin: 5px;
  color: inherit;
  text-decoration: none;
  font-size: var(--normal-font);
}

.resume_container .subheading {
  margin: 6px 0;
  color: #cfcfcf;
  font-size: var(--semi-font);
}

.resume_container .summary {
  display: flex;
  margin-top: 5px;
}

.resume_container .summary p {
  font-size: var(--normal-font);
  line-height: 1.5;
  color: #777778;
}

.resume_container .divider {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.resume_container .divider p {
  margin: 0 10px 0 0;
  font-size: var(--normal-font);
  font-weight: 600;
  color: #e2e2e2;
}

.resume_container .divider span {
  flex-grow: 1;
  height: 1px;
  background-color: #77777859;
}

.resume_container .skills p {
  font-size: var(--normal-font);
  font-family: 'air-light';
  color: #777778;
  display: flex;
  margin: 0;
  line-height: 2;
}


.resume_container .exp_container .experience .title {
  margin: 0;
  /* font-size: 15px; */
  font-size: var(--semi-font);
  line-height: 2;
}

.resume_container .exp_container .experience a {
  color: #fff
}

.resume_container .exp_container .experience .title span {
  font-size: 12px;
  color: #777778;
  margin-left:5px;
}

.resume_container .exp_container .experience .description {
  display: block;
  font-size: var(--normal-font);
  line-height: 1.5;
  color: #777778;
  margin: 10px 0;
  padding: 0;
}

.resume_container .exp_container .experience .description  p {
  margin: 2px 0;
  width: 100%;
  color:  #777778;
  display: flex;
}

.resume_container .exp_container .experience .description  p span {
  margin-right: 5px;
}

.resume .go_button_container {
  display: none;
}

@media (max-width: 993px) {
  .resume_container {
    margin: 30px 0;
  }

  .resume_container .top_row .buttons {
    align-self: unset;
  }

  .resume_container .top_row .buttons span {
   display: none;
  }

  .resume .go_button_container {
    display: flex;
    margin-bottom: 20px;
  }

  .resume_container .top_row .buttons a {
    margin: 7px;
    font-size: 20px;

  }
  
}