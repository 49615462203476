.work {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.work .go_button_container {
  display: none;
}

.work .portfolio_showcase {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.work .go_button_container {
  display: none;
}


@media (max-width: 993px) {
  .work {
    margin: 30px 0px;
  }

  .work .go_button_container {
    display: flex;
  }
}