.box_container {
  width: 100%;
  background-color: #16161A;
  margin: 0.5%;
  position: relative;

}

.box {
  display: flex;
  flex-direction: column;
  padding: 10%;
  /* height: 80%; */
  flex-grow: 1;
  justify-content: space-between;
  cursor: default;
  align-items: self-end;
}

.box_container:hover {
  transform: scale(1.02);
}

.box .project_details h3 {
  margin: 0;
  font-size: var(--h3-font);
}

.box .project_details p {
  font-size: var(--normal-font);
  line-height: 2;
  color: #777778;
  height: 5rem;
  font-family: 'air-light';
  font-weight: 500;
}

.box .project_details small {
  margin-bottom: 13px;
  font-size: var(--small-font);
  color: grey;
}

.box .project_links a {
  font-size: var(--small-font);
  line-height: 21px;
  color: inherit;
  text-decoration: none;
  margin-right: 0.8rem;
  color: grey;
}

.box .project_links a i {
  margin-right: 2px;
}

@media (max-width: 993px) {
  .box_container {
    width: 99%;
    margin: 4% 10px;
  }

  .box .project_details p {
    height: max-content;
  }
}