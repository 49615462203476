#mobile {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  /* overflow: hidden !important; */
  position: relative;
  /* padding-bottom: 10%; */
}

#mobile .base_container {
  padding: 0;
  height: auto;
  flex-grow: unset;
}

#mobile .base_container nav {
  display: none;
}

#mobile .base_container .footer {
  display: none;
}

#mobile .base_container .hero {
  justify-content: center;
}

#mobile .base_container .hero .intro_box {
  flex-direction: column-reverse;
  margin: 0;
}

#mobile .base_container .hero .intro_box h1 {
  font-size: 26px;
  text-align: center;
  margin: 0;
  line-height: 1.5;
  padding: 15px;
  padding-bottom: 0;
}

#mobile .base_container .hero .description {
  justify-content: center;
  text-align: center;
  padding: 0;
}

#mobile .base_container .hero .intro_box h1 br {
  display: none;
}

@media screen and (max-width: 450px) {
  #mobile .base_container .hero .intro_box h1 br {
    display: initial;
  }
}

.mobile_container .social_buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.mobile_container .social_buttons a {
  margin: 3% 6%;
  color: inherit;
  font-size: 1.25rem;
  text-decoration: none;
  display: flex;
}

.mobile_container .social_buttons .credits {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: .5rem 0 0;
  margin: 0;
}

.mobile_container .social_buttons .credits p {
  display: flex;
  width: fit-content;
  box-sizing: border-box;
  margin: .2rem 0 0;
  padding: 0;
  justify-content: center;
  font-size: var(--xsmall-font) !important;
  color: #777778;
}

.mobile_container .social_buttons .credits p  a {
  margin: 0;
  font-size: var(--xsmall-font) !important;
  text-decoration: none;
  color: #cecece;
  margin: 0 5px;
}

/*===== BUTTON STYLE =====*/

.go_button_container {
  display: flex;
  justify-content: center;
  margin: 20px 0 0;
}

.go_button_container a {
  margin: 5px;
  text-decoration: none;
  width: 40%;
}

.go_button_container a.explore {
  width: fit-content;
}

.go_button_container button {
  background-color: #16161A;
  color: #fff;
  padding: 0.7rem 2rem;
  font-size: var(--small-font);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'air-regular';
  width: 100%;
  box-sizing: border-box;
}

.go_button_container button span {
  position: relative;
  top: 1px;
  left: 2px;
  display: none;
}

.go_button_container button.explore {
  font-size: 15px;
  width: fit-content;
  padding: 0.6rem 2rem;
}

.go_button_container button.explore span {
  display: initial;
  position: relative;
}

.go_button_container button:hover {
  border: 1px solid #A6A6A7;
}

.go_button_container button:focus {
  outline: none;
}

.go_button_container button b {
  transform: rotate(180deg);
  margin: 0;
  font-weight: 100;
  margin-right: 5px;
}
