form {
  display: flex;
  flex-direction: column;
}

form .form_section {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

form .form_section label {
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: var(--h3-font);
  font-size: var(--semi-font);
}

form .form_section label input, textarea {
  background: #16161A;
  color: lightgray;
  border: 1px solid transparent;
  padding: 0.9rem;
  margin-top: 7px;
  font-family: 'air-regular';
  font-size: 1em;
  display: flex;
  align-items: center;
}

form .form_section label input:focus, textarea:focus {
  border-color: rgb(75, 75, 75);
  outline: none;
}

form .form_section label input::placeholder, textarea::placeholder  {
  color: #777778;
  font-size: var(--small-font);
  line-height: 2;
  font-family: 'air-regular';
  font-size: 1em;
}

form .form_section button {
  background-color: #16161A;
  color: #fff;
  border: none;
  padding: 0.9rem;
  font-size: var(--semi-font);
  font-family: 'air-regular';
}

form .form_section button:focus {
  outline: none;
  border: none;
}

form input:-webkit-autofill,
form input:-webkit-autofill:hover, 
form input:-webkit-autofill:focus, 
form input:-webkit-autofill:active  {
  border-color: rgb(75, 75, 75);
  box-shadow: 0 0 0 30px #16161A inset !important;
  -webkit-box-shadow: 0 0 0 30px #16161A inset !important; 
  -webkit-text-fill-color: #777778;
}